import * as types from './mutation_types'
import {
  destroy, update, cursorPaginate, formatResponseData,
} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/configuration/user/'
export default {
  namespaced: true,
  state: {
    items: [],
    usersForModal: [],
    filterable: [],
    item: [],
    itemCountUsers: [],
    drivers: [],
    sellers: [],
    entry_drivers: [],
    notifications: [],
    activeSellersSimplified: [],
    activeUsersSimplified: [],
    activeSellersAllocatedByUserId: [],
    activeUsersIntermediaries: [],
    userPlants: [],
  },
  getters: {
    show: state => state.item,
    showCountUsers: state => state.itemCountUsers,
    filterable: state => state.filterable,
    fetch: state => state.items,
    fetchForModalList: state => state.usersForModal,
    getDrivers: state => state.drivers,
    sellers: state => state.sellers,
    getNotifications: state => state.notifications,
    getSellerWithAll: state => {
      return [{ id: 'all', name: 'Todos' }, ...state.filterable]
    },
    getAllActiveSellersSimplified: state => state.activeSellersSimplified,
    getAllActiveUsersSimplified: state => state.activeUsersSimplified,
    getAllActiveSellersAllocatedByUserId: state => state.activeSellersAllocatedByUserId,
    getEntryDrivers: state => state.entry_drivers,
    getActiveUsersIntermediaries: state => state.activeUsersIntermediaries,
    getUserPlants: state => state.userPlants,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_MODAL_LIST](state, payload) {
      state.usersForModal = payload
    },
    [types.SET_FILTERABLE](state, payload) {
      state.filterable = payload
    },
    [types.SET_DRIVER](state, payload) {
      state.drivers = payload
    },
    [types.SET_SELLER](state, payload) {
      state.sellers = payload
    },
    [types.SET_NOTIFICATIONS](state, payload) {
      state.notifications = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.SHOW_COUNT_USERS](state, payload) {
      state.itemCountUsers = payload
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid)
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    },
    [types.SET_ACTIVE_SELLERS_SIMPLIFIED](state, payload) {
      state.activeSellersSimplified = payload
    },
    [types.SET_ACTIVE_USERS_SIMPLIFIED](state, payload) {
      state.activeUsersSimplified = payload
    },
    [types.SET_ACTIVE_SELLERS_ALLOCATED_BY_USER_ID](state, payload) {
      state.activeSellersAllocatedByUserId = payload
    },
    [types.SET_ENTRY_DRIVERS](state, payload) {
      state.entry_drivers = payload
    },
    [types.SET_ALL_INTERMEDIARIES](state, payload) {
      state.activeUsersIntermediaries = payload
    },
    [types.SET_USER_PLANTS](state, payload) {
      state.userPlants.push(payload);
    },
  },
  actions: {
    fetchItems({ commit }, params) {
      return createAxios().get(endPoint, { params: params })
        .then(({ data }) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsForModalList({ commit }, params) {
      return createAxios().get(endPoint + 'active-sellers', { params: params })
        .then(({ data }) => {
          commit(types.SET_MODAL_LIST, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsFilterable({ commit }, params) {
      return createAxios().get(endPoint, { params: params })
        .then(({ data }) => {
          commit(types.SET_FILTERABLE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({ state }, params) {
      return createAxios()
        .get(endPoint, { params: params })
        .then(({ data }) => {
          cursorPaginate(state, {
            data: formatResponseData(data).data,
            next_page: params.page,
          });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    fetchItemsPaginateNewApi({ state }, params) {
      return createAxios()
        .get(endPoint + 'list/paginated', { params: params })
        .then(({ data }) => {
          cursorPaginate(state, {
            data: formatResponseData(data).data,
            next_page: params.page,
          });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getByCompanyPlant({ commit }, payload) {
      return createAxios().get(endPoint + 'company-plant/' + (typeof payload === 'string' ? payload : payload.company_plant_uuid), { params: payload })
        .then(({ data }) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchDrivers({ commit }, params) {
      return createAxios().get(`configuration/user-drivers`, { params: params })
        .then(({ data }) => {
          commit(types.SET_DRIVER, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({ commit, state }, payload) {
      return createAxios().post(endPoint, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({ commit }, payload) {
      return createAxios().put(`${endPoint}${payload.uuid}`, payload)
        .then(({ data }) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({ commit, dispatch }, uuid) {
      return createAxios().get(endPoint + uuid)
        .then(({ data }) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({ commit, dispatch }, uuid) {
      return createAxios().delete(endPoint + uuid)
        .then(({ data }) => {
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    setViewSellers({ commit, dispatch }, params) {
      return createAxios().post(endPoint + 'set-sellers', params)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    setBankAccount({ commit, dispatch }, params) {
      return createAxios().post(endPoint + 'set-bank_accounts', params)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchSellers({ commit }) {
      return createAxios().get(endPoint + 'sellers')
        .then(({ data }) => {
          commit(types.SET_SELLER, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getDocument({ commit, dispatch }, id) {
      return createAxios().get(`${endPoint}document/${id}`)
        .then(({ data }) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCountUsers({ commit, dispatch }) {
      return createAxios().get(`${endPoint}total-users`)
        .then(({ data }) => {
          commit(types.SHOW_COUNT_USERS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getSellersAllocatedByPlant({ commit }, plant_uuid) {
      return createAxios().get(`${endPoint}allocated-by-plant/${plant_uuid}`)
        .then(({ data }) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getUserNotifications({ commit }, user_id) {
      return createAxios().get(`${endPoint}notifications/${user_id}`)
        .then(({ data }) => {
          commit(types.SET_NOTIFICATIONS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    updateStatus({ }, payload) {
      return createAxios().put(`${endPoint}update-status/${payload.id}/${payload.status}`)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    setNotify({ }, params) {
      return createAxios().put(`${endPoint}notify/${params.user_id}`, params)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAllActiveSellersSimplified({ commit }) {
      return createAxios().get(endPoint + 'sellers-simplified/active')
        .then(({ data }) => {
          commit(types.SET_ACTIVE_SELLERS_SIMPLIFIED, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAllActiveUsersSimplified({ commit }) {
      return createAxios().get(endPoint + 'users-simplified/active')
        .then(({ data }) => {
          commit(types.SET_ACTIVE_USERS_SIMPLIFIED, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAllActiveSellersAllocatedByUserId({ commit }, id) {
      return createAxios().get(endPoint + 'allocated-by-id/' + id)
        .then(({ data }) => {
          commit(types.SET_ACTIVE_SELLERS_ALLOCATED_BY_USER_ID, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAllWithFilter({ commit }, params) {
      return createAxios().get(`${endPoint}with-filter`, { params: params })
        .then(({ data }) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getSearch({ commit }, params) {
      return createAxios().get(`${endPoint}search/filterable`, { params: params })
        .then(({ data }) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getSellersSimplified({ commit }) {
      return createAxios().get(endPoint + 'sellers/simplified')
        .then(({ data }) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getEntryDrivers({ commit }) {
      return createAxios().get(`${endPoint}` + 'driver/is-entry-driver')
        .then(({ data }) => {
          commit(types.SET_ENTRY_DRIVERS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    userGetAllIntermediary({ commit }) {
      return createAxios().get(endPoint + 'simplified/all-intermediary')
        .then(({ data }) => {
          commit(types.SET_ALL_INTERMEDIARIES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getUserPlants({ commit }, userUuid) {
      return createAxios().get(`${endPoint}` + 'user_has_plant/' + `${userUuid}`)
        .then(({ data }) => {
          commit(types.SET_USER_PLANTS, data.data);
          return Promise.resolve(data);
        }).catch(error => {
          return Promise.reject(error)
        })
    }
  }
}
