<template>
  <div class="row card-wrapper" v-if="requests.length">
    <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(item, index) in requests" :key="index">
      <div class="card main-card">
        <div class="px-3 pt-3 pb-1 d-flex justify-content-between">
          <div>
            <div class="mb-2">
              <div>
                <div style="font-size: 12px; font-weight: 400; color: #606062;">{{ item.code }}</div>
                <div></div>
              </div>
            </div>
            <div class="d-flex">
              <span style="font-size: 18px ;font-weight: 600; color: #2B2D32;">
                {{ item.total_amount | currency() }}</span>
              <div class="align-self-center card-status mr-3 mb-1 pointer" :class="getStatusClass(item.order_status)">
                {{ getStatusText(item.order_status) }}
              </div>
            </div>
            <div class="mt-1">
              <span style="font-size: 12px ;font-weight: 400; color: #606062;">
                {{ item.deadline | parseDate("DDMMMYYYY") }}
              </span>
            </div>
          </div>
          <div class="position-relative">
            <!-- Menu engrenagem -->
            <base-dropdown menuOnRight class="d-flex justify-content-end" style="height: fit-content">
              <div slot="title-container" class="dropdown-toggle rounded">
                <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
              </div>
              <div>
                <a class="dropdown-item d-flex" style="font-size: 12px;">
                  <img width="18" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new" />
                  Editar
                </a>
              </div>
              <div>
                <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                <a class="dropdown-item d-flex" style="font-size: 12px;">
                  <img width="18" height="18" src="/img/icons/order-history.png">
                  Transferir
                </a>
              </div>
              <div>
                <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                <a class="dropdown-item d-flex" style="font-size: 12px;">
                  <img width="18" src="/img/icons/icons8/ios/delete_danger.png">
                  Excluir
                </a>
              </div>
            </base-dropdown>
            <!-- Icones -->
            <div style="" class="d-flex pointer position-absolute bottom-0 right-0">
              <div v-if="item.has_attachments" class="mr-2" @click.prevent="handleShowAttachments(item.id)">
                <img src="/img/icons/paperclip-green.png" width="22">
              </div>
              <div class="mr-2" @click="handleShowModal({ ref: 'modalListItemsApproval', data: item })">
                <span slot="reference">
                  <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                </span>
              </div>
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <span class="font-weight-500">Solicitante:</span>
                  <div style="font-size: 11px">{{ item.created_by_user_name }}</div>
                  <div style="font-size: 11px">{{ item.created_by_user_email }}</div>
                  <span slot="reference">
                    <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                  </span>
                </el-popover>
              </div>
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div class="p-0" style="max-width: 14rem;">
                    <span style="font-size: 14px; font-weight: 500;">Observações</span>
                    <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                    <div class="d-block mt-2">
                      <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                      <div style="font-size: 12px;" class="mt-1">{{ item.note }}</div>
                    </div>
                    <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                    <div class="d-block mt-2">
                      <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                      <div style="font-size: 12px;" class="mt-1">{{ item.purchase_justification }}</div>
                    </div>
                  </div>
                  <span slot="reference">
                    <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22" />
                  </span>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown-divider mx-3" />
        <div class="ml-3">
          <span style="font-size: 14px ;font-weight: 400; color: #2B2D32;">{{ item.purchase_category_description
            }}</span>
        </div>
        <div class="ml-3 mb-3">
          <span style="font-size: 12px ;font-weight: 400; color: #606062;">{{ item.entity_document }}</span>
        </div>
        <!-- Botoes de aprovacao -->
        <div class="d-flex mb-3 text-center justify-content-around">
          <div class="ml-2 mb-1 aprovation-button"
            @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.APPROVED)">
            <img class="mr-2" width="20" src="/img/icons/icons8/ios/thumb-up_primary.png">
            Aprovar
          </div>
          <div class="mr-2 mb-1 aprovation-button"
            @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.DENIED)">
            <img class="mr-2" width="20" src="/img/icons/icons8/ios/thumbs-down_danger.png">
            Negar
          </div>
        </div>
      </div>
    </div>
    <ModalListItemsApproval ref="modalListItemsApproval" />
  </div>
</template>
<script>

import ModalListItemsApproval from "./Shared/_ModalListItemsApproval";
import { PurchaseOrderStatusEnum } from "@/enum/PurchaseOrderStatusEnum";

export default {
  name: "PurchasesOrderCards",
  components: {
    ModalListItemsApproval
  },
  props: {
    requests: Array,
  },
  data() {
    return {
      PurchaseOrderStatusEnum: PurchaseOrderStatusEnum,
    }
  },
  methods: {
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
    updateStatusOrder(purchaseOrder, status) {
      let loader = this.$loading.show();
      purchaseOrder.status = status;
      this.$store.dispatch('purchaseOrder/update', purchaseOrder)
        .then(() => {
          this.$emit('fetch')
          loader.hide();
          this.$notify({ type: 'success', message: 'Status atualizado' });
        })
        .catch(() => {
          loader.hide();
          this.$notify({ type: 'error', message: 'Erro ao atualizar o status!' });
        });
    },
    getStatusText(status) {
      switch (status) {
        case this.PurchaseOrderStatusEnum.PENDING: return "Pendente";
        case this.PurchaseOrderStatusEnum.APPROVED: return "Aprovado";
        case this.PurchaseOrderStatusEnum.DENIED: return "Negado";
        default: return "Cancelado";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case this.PurchaseOrderStatusEnum.PENDING: return "pending-status";
        case this.PurchaseOrderStatusEnum.APPROVED: return "approved-status";
        case this.PurchaseOrderStatusEnum.DENIED: return "denied-status";
        default: return "denied-status";
      }
    },
  }
};
</script>

<style scoped>
.main-card {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: none !important;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
  margin-bottom: 1.6rem !important;
}

.card-status {
  border-radius: 5px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.2rem 0.6rem;
  margin-left: 0.7rem;
  margin-top: 0.1rem;
}

.aprovation-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 45%;
  height: 2rem;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
}

.aprovation-button:hover {
  cursor: pointer;
  transform: translateY(-3px);
}

.pending-status {
  background-color: #fdf2db;
  color: #F2B532;
}

.approved-status {
  background-color: #d2e3f1;
  color: #1A70B7;
}

.denied-status {
  background-color: #f8dad7;
  color: #DB4539;
}
</style>
