import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store'
import axios from 'axios'
import { hasPrivilege, hasPermission } from "@/plugins/permissions";
import * as helpers from '@/helpers'
import { rules } from '@/validate/validate'
import Loading from 'vue-loading-overlay'
import vSelect from 'vue-select'
import 'babel-polyfill'
import { exceptionHandler } from './shared/exceptions'

// Configuração global de handlers de exceção
 window.onerror = exceptionHandler.handleGlobalError;
 window.onunhandledrejection = exceptionHandler.handleUnhandledRejection;
 Vue.config.errorHandler = exceptionHandler.handleVueError;

const global = Vue.observable({ filterable: {} });
const user = Vue.observable({ user: {} });
Object.defineProperty(Vue.prototype, '$filterable', {
  get() {
    return global.filterable;
  },
  set(value) {
    global.filterable = value;
  }
});
Object.defineProperty(Vue.prototype, '$user', {
  get() {
    return user.filterable;
  },
  set(value) {
    user.filterable = value;
  }
});
import VCalendar from 'v-calendar';
Vue.use(VCalendar);
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";

Vue.use(VueFileAgent);
Vue.prototype.$helper = helpers
Vue.prototype.$hasPrivilege = hasPrivilege
Vue.prototype.$hasPermission = hasPermission
Vue.prototype.$axios = axios;
Vue.prototype.$current_domain = window.location.host.split(".")[0];

import 'vue-loading-overlay/dist/vue-loading.css'
import "vue-snotify/styles/material.css";
import DashboardPlugin from './plugins/dashboard-plugin'
import '@/plugins'
import '@/filters'

Vue.use(Loading, { color: '#6260F5', backgroundColor: '#000', loader: 'spinner' })
Vue.use(DashboardPlugin)
import Snotify from 'vue-snotify';

Vue.use(Snotify)

window.EventBus = new Vue()
import "./style/styles.scss";

if (process.env.NODE_ENV === 'production') {
  (function (d, w, c) {
    w.ChatraID = 't83DEu73pT7vMxz6A';
    var s = d.createElement('script');
    w[c] = w[c] || function () {
      (w[c].q = w[c].q || []).push(arguments);
    };
    s.async = true;
    s.src = 'https://call.chatra.io/chatra.js';
    if (d.head) d.head.appendChild(s);
  })(document, window, 'Chatra');
}
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
